<template>
  <div class="container">
    <h1>Bližšie informácie o projekte čoskoro</h1>
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>
